.header {
    position: fixed;
    top: 0;
    padding: 40px 80px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 767px) {
    .header {
        padding: 24px 40px;
    }
}

.scrolled .menuLangs {
    transform: translateY(-30px);
    opacity: 0;
}

.scrolled .menuLangs.hovered {
    transform: translateY(0px);
    opacity: 1;
}

.container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
}

.menuLangs {
    display: flex;
    align-items: center;
    transition: .3s;
    gap: 40px;
}

@media (max-width: 1200px) {
    .menuLangs {
        display: none;
    }
}

.menuItems {
    display: flex;
    align-items: center;
    gap: 40px;
    font-size: 20px;
    color: #CCCCCC;
}

.menuItems a {
    opacity: 0.4;
    transition: .3s;
    text-decoration: none;
    color: inherit;
}

.burgerMenu {
    position: absolute;
    right: 0;
    transition: .3s;
    opacity: 0;
    transform: translateX(30px);
}

@media (max-width: 1200px) {
    .burgerMenu {
        position: relative;
        right: unset;
        opacity: 1 !important;
        transform: translateX(0) !important;
    }
}

.menuItems a:hover {
    opacity: 1;
}

.scrolled .burgerMenu {
    opacity: 1;
    transform: translateY(0px);
}

.burgerMenu.hovered {
    opacity: 0;
    transform: translateX(30px);
}

.burgerOpened.burgerMenu {
    opacity: 0 !important;
}
