.root {
    padding: 0 80px;
}

@media (max-width: 1170px) {
    .root {
        padding: 0 40px;
        margin-bottom: 90px;
    }
}

@media (max-width: 767px) {
    .root {
        padding: 0 15px;
    }
}

.border {
    width: 100%;
    height: 1px;
    background: #FCFCFC;
    opacity: 0.2 !important;
    margin-top: 125px;
    margin-bottom: 150px;
}

@media (max-width: 767px) {
    .border {
        margin-top: 120px;
        margin-bottom: 90px;
    }
}
