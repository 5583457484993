.root {
    width: 47px;
    height: 47px;
    background: #FFFFFF0D;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
    box-shadow: none;
    border: none;
    outline: none;
}
