.wrapper {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;

    font-size: 20px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.4);
}

.input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
}

.checkmark::after {
    content: "";
    position: absolute;
    display: block;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #A94007;
}

.input:checked ~ .checkmark::after {
    opacity: 1;
}

@media screen and (max-width: 767px) {
    .wrapper {
        font-size: 12px;
        line-height: 14px;
    }
}