.root {
    margin-bottom: 120px;
}

@media (max-width: 767px) {
    .root {
        margin-bottom: 150px;
    }
}

.title {
    margin-bottom: 100px;
}

@media (max-width: 767px) {
    .title {
        margin-bottom: 120px;
    }
}

.container {
    padding: 0 95px;
    position: relative;
    margin-bottom: 120px;
}

@media (max-width: 767px) {
    .container {
        padding: 0 20px;
    }
}

.image {
    padding-top: 80%;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 15px;
}

.image:hover img {
    transform: scale(1.1);
}

.image img {
    position: absolute;
    inset: 0;
    object-fit: cover;
    transition: .3s;
    width: 100%;
    height: 100%;
}

.gradient {
    position: absolute;
    inset: 0;
    background: #100C0866;
    width: 100%;
    height: 100%;
    transition: .3s;
}

@media (max-width: 767px) {
    .gradient {
        opacity: 0;
    }
}

.image:hover .gradient {
    opacity: 0;
}

.image svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.duration {
    position: absolute;
    right: 25px;
    bottom: 15px;
    z-index: 2;
    background: #CCCCCC66;
    font-size: 14px;
    padding: 5px 8px;
    border-radius: 10px;
    color: #100C08;
}

.sliderTitle {
    font-size: 20px;
    margin-bottom: 15px;
}

.sliderText {
    font-size: 16px;
    color: #D1D1D1;
    line-height: 18px;
    font-weight: 300;
}

.sliderArrow {
    position: absolute;
    top: 50%;
}

@media (max-width: 767px) {
    .sliderArrow {
        display: none;
    }
}

.sliderArrowLeft {
    left: 20px;
}

.sliderArrowRight {
    right: 25px;
}

.stick {
    width: calc(100% - 160px);
    margin: auto;
    height: 1px;
    background: #FCFCFC;
    opacity: 0.2;
}

@media (max-width: 767px) {
    .stick {
        width: calc(100% - 40px);
    }
}
