.head {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
    gap: 60px;
    position: relative;
    z-index: 2;
}

.title h2 {
    text-align: left;
}

@media (max-width: 1170px) {
    .title h2 {
        font-size: 44px;
        line-height: 44px;
    }
}

.side {
    display: flex;
    align-items: center;
    gap: 44px;
}

@media (max-width: 767px) {
    .side {
        gap: 20px;
        justify-content: center;
        width: 100%;
    }
}

.arrows {
    display: flex;
    align-items: center;
    gap: 25px;
}

.slideContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.slideItem {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 45%;
}

.slideItem:nth-child(1) {
    width: 60%;
    border-radius: 20px 0 0 0;
}

.slideItem:nth-child(2) {
    width: calc(40% - 15px);
}

.slideItem:nth-child(3) {
    width: 100%;
}

.slideItem:nth-child(4) {
    width: calc(40% - 15px);
}

.slideItem:nth-child(5) {
    width: 60%;
}

.slideItem:nth-child(6) {
    width: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
}

.layer {
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(16, 12, 8, 0) 69.24%, #100C08 100%);
    opacity: 1;
    transition: .3s;
}

.text {
    position: absolute;
    left: 20px;
    bottom: 15px;
    color: white;
    opacity: 1;
    transition: .3s;
}

.text h2 {
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: bold;
}

.text h3 {
    font-weight: 300;
    font-size: 10px;
}
