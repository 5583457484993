.link {
    display: block;
    text-decoration: none;
}

.slider {
    margin-top: 70px;
    padding: 0 35px;
    position: relative;
}

.image {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 15px;
    opacity: 0.6;
    transition: .3s;
}

.link:hover .image {
    opacity: 1;
}

.swiperSlide {
    width: 30%;
    background: #100C08;
}

.swiperSlide:nth-child(2) {
    width: 40%;
}

.title {
    color: #D1D1D1;
    font-weight: 300;
}

.arrow {
    width: 44px;
    height: 44px;
    background: #CCCCCC66;
    border-radius: 10px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s;
    cursor: pointer;
}

.left {
    left: 50px;
}

.left svg {
    transform: rotate(180deg);
}

.right {
    right: 50px;
}

@media (max-width: 767px) {
    .image {
        height: 180px;
    }

    .title {
        font-size: 12px;
    }

    .left, .right {
        display: none;
    }
}
