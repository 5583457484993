.root {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

@media (max-width: 767px) {
    .root {
        gap: 7px;
    }
}

.root:hover .logoText {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.logoText {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transition: .3s;
}

@media (max-width: 1200px) {
    .logoText {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

@media (max-width: 767px) {
    .logo {
        width: 25px;
        height: 25px;
    }

    .logoText {
        width: 50px;
        height: 25px;
    }
}

.isFooter .logoText {
    clip-path: none;
    width: 158px;
    height: 76px;
}

.isFooter .logo {
    width: 76px;
    height: 76px;
}
