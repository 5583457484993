.top {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 130px 80px 85px;
}

.title {
    margin-bottom: 30px;
}

.title > h2 {
    font-size: 96px;
    line-height: 98px;
    text-align: left;
}

.description {
    font-size: 18px;
    line-height: 21px;
}


.topContent {
    margin-left: 80px;
    margin-top: 110px;
    max-width: 420px;
}

@media screen and (max-width: 767px) {
    .top {
        padding: 130px 40px 85px;
    }

    .topContent {
        margin-top: 92px;
        max-width: 275px;
        margin-left: 0;
    }

    .title {
        margin-bottom: 10px;
    }

    .title > h2 {
        font-size: 36px;
        line-height: 37px;
    }

    .description {
        font-size: 12px;
        line-height: 14px;
        max-width: 217px;
    }
}


.colabs {
    position: relative;
    padding: 315px 80px 120px 160px;
    display: flex;
    gap: 160px;
}

.colabsTitle {
    position: sticky;
    top: 0;
    right: 0;
    writing-mode: vertical-lr;
    padding-top: 100px;
}

.colabsList:before,
.colabsList:after {
    content: '';
    position: absolute;
    border: 1px solid #FCFCFC;
    opacity: 0.4;
}


.colabsList:before {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-left-radius: 50px;

    top: -200px;
    width: 100%;
    height: 100px;
}

.colabsList:after {
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-right-radius: 50px;

    top: -99px;
    bottom: 0;
    right: -100px;
    width: 97px;
}

.colabsTitle > h2 {
    text-align: left;
}

.colabsList {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 120px;
    padding-bottom: 195px;
}

@media screen and (max-width: 992px) {
    .colabs {
        padding-left: 80px;
    }

    .colabsList {
        padding-bottom: 100px;
    }
}

@media screen and (max-width: 767px) {
    .colabs {
        flex-direction: column-reverse;
        align-items: center;
        padding: 153px 30px 140px 30px;
        gap: 80px;
    }

    .colabsList:before,
    .colabsList:after {
        display: none;
    }

    .colabsList {
        gap: 40px;
        padding-bottom: 0;
    }

    .colabsTitle {
        writing-mode: horizontal-tb;
        text-align: center;
        position: relative;
    }

    .colabsTitle > h2 {
        font-size: 20px;
        line-height: 20px;
    }

    .colabsTitle:before,
    .colabsTitle:after {
        content: '';
        position: absolute;
        border: 1px solid #FCFCFC;
        opacity: 0.2;
    }

    .colabsTitle:before {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-left-radius: 25px;

        top: -113px;
        left: 0;
        right: -26px;
        height: 70px;
    }

    .colabsTitle:after {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-left-color: transparent;

        left: calc(100% + 25px);
        right: -50px;
        top: -42px;
        height: 48px;
    }
}
