.root {
    position: relative;
    margin-bottom: 80px;
}

@media (max-width: 767px) {
    .root {
        margin-bottom: 120px;
    }
}

.counter {
    font-family: 'PPFragment', sans-serif;
    position: absolute;
    right: 160px;
    bottom: 90px;
    z-index: 2;
    font-size: 48px;
    display: flex;
}

@media (max-width: 1023px) {
    .counter {
        right: 100px;
    }
}

@media (max-width: 767px) {
    .counter {
        font-size: 16px;
        line-height: 16px;
        right: 40px;
        bottom: 136px;
    }
}

.activeIndex {
    font-size: 96px;
    line-height: 98px;
    margin-right: 30px;
    color: #A94007;
}

@media (max-width: 767px) {
    .activeIndex {
        font-size: 36px;
        line-height: 36px;
        margin-right: 15px;
    }
}

.stick {
    margin-right: 15px;
}

@media (max-width: 767px) {
    .stick {
        margin-right: 5px;
    }
}

.slider {
    width: 100%;
    height: 100vh;
}

.slide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 160px;
}

@media (max-width: 1023px) {
    .slide {
        padding: 0 100px;
    }
}

@media (max-width: 767px) {
    .slide {
        padding: 0 40px;
    }
}

.shadow {
    position: absolute;
    inset: 0;
    background: linear-gradient(270deg, rgba(16, 12, 8, 0) 0%, #100C08 100%);
}

.content {
    position: relative;
    max-width: 420px;
}

@media (max-width: 767px) {
    .content {
        margin-top: -80px;
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.title {
    font-family: 'PPFragment', sans-serif;
    font-size: 96px;
    line-height: 98px;
    margin-bottom: 30px;
    position: relative;
}

@media (max-width: 1023px) {
    .title {
        font-size: 66px;
        line-height: 68px;
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .title {
        font-size: 36px;
        line-height: 37px;
        margin-bottom: 10px;
    }
}

.text {
    font-size: 18px;
}

@media (max-width: 767px) {
    .text {
        font-size: 12px;
        line-height: 13px;
    }
}

.rectangle {
    top: -15px;
    right: calc(100% - 60px);
}

@media (max-width: 767px) {
    .rectangle {
        right: calc(100% - 88px);
    }
}
