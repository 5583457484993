.content {
    background-color: rgba(84, 83, 82, 0.3);
    padding: 53px 80px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 97px;
}

.content h2 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 25px;
    text-align: left;
}

.logo {
    flex: 1;
    align-self: center;
}

.linksList {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.linksList > a {
    color: inherit;
    text-decoration: none;
}

.linksList, .address {
    font-size: 18px;
    line-height: 21px;
}

.address {
    margin-bottom: 15px;
}

.addresses {
    max-width: 275px;
}

.addresses a {
    font-weight: 300;
    color: inherit;
    text-decoration: none;
}

.button {
    margin-top: 17px;
}

.iconsList {
    display: flex;
    gap: 24px;
}

.copyright {
    padding: 20px 80px;
    display: flex;
    justify-content: center;

    font-size: 14px;
    line-height: 16px;
}

.copyright > * {
    flex: 1;
}

.terms {
    display: flex;
    gap: 40px
}

.terms > a {
    color: inherit;
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .logo {
        flex: 0;
    }
}

@media screen and (max-width: 767px) {
    .content {
        padding: 60px 50px;
        gap: 50px;
    }

    .content .logo {
        display: none;
    }

    .content h2 {
        font-size: 18px;
        line-height: 18px;
    }

    .linksList, .address {
        font-size: 14px;
        line-height: 16px;
    }

    .addresses {
        order: 3;
    }

    .icons {
        order: 2;
    }

    .iconsList {
        flex-direction: column;
    }

    .copyright {
        padding-top: 0;
        flex-direction: column-reverse;
        align-items: center;
        gap: 25px;

        font-size: 10px;
        line-height: 11px;
    }
}