.root {
    margin-bottom: 120px;
}

.title {
    font-size: 32px;
    line-height: 32px;
    font-family: 'PPFragment', sans-serif;
    margin-bottom: 115px;
}

.title span {
    display: inline-flex;
    margin-left: 40px;
    transform: translateY(10px);
}

@media (max-width: 767px) {
    .title {
        font-size: 20px;
        line-height: 21px;
        margin-bottom: 60px;
    }

    .title svg {
        width: 78px;
        height: 50px;
    }

    .title span {
        margin-left: 20px;
        transform: translateY(0);
    }
}

.container {
    padding: 0 80px;
}

@media (max-width: 1023px) {
    .container {
        padding: 0 30px;
    }
}

.slide {
    padding-top: 138%;
    position: relative;
    border-radius: 0 0 80px 80px;
    overflow: hidden;
}

@media (max-width: 1023px) {
    .slide {
        border-radius: 0 0 40px 40px;
    }
}

.slide img, .slide .layer {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
}

.layer {
    background: linear-gradient(180deg, rgba(16, 12, 8, 0) 69.24%, #100C08 100%);
}

.text {
    position: absolute;
    left: 60px;
    bottom: 60px;
    font-size: 20px;
    color: #D1D1D1;
    padding-right: 10px;
    font-weight: bold;
}

@media (max-width: 1023px) {
    .text {
        left: 25px;
        bottom: 35px;
    }
}

@media (max-width: 767px) {
    .text {
        font-size: 10px;
    }
}


