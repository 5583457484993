.sliderContainer {
    display: flex;
    align-items: center;
}

.slider {
    margin: 0 15px;
}

.slideItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

@media screen and (max-width: 767px) {
    .sliderContainer {
        width: 95%;
    }

    .slider {
        margin: 0;
    }

    .slideItem img {
        max-width: 54px;
    }

    .sliderPrev, .sliderNext {
        display: none;
    }
}