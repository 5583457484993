.root {
    position: relative;
    font-family: 'PPFragment', sans-serif;
}

.title {
    font-size: 64px;
    line-height: 65px;
    position: relative;
    text-align: center;

    text-transform: uppercase;
}

@media (max-width: 1023px) {
    .title {
        font-size: 44px;
        line-height: 45px;
    }
}

@media (max-width: 767px) {
    .title {
        font-size: 36px;
        line-height: 36px;
    }
}

.rectangle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rectangle.left {
    top: -20px;
    left: -80px;
    transform: none;
}

@media (max-width: 767px) {
    .rectangle.left {
        top: -6px;
        left: -40px;
    }
}
