.root {
    padding-top: 130px;
}

.breadcrumbs {
    margin-left: 80px;
}

.container {
    margin-top: 60px;
    padding: 0 80px;
    margin-bottom: 120px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .breadcrumbs {
        margin-left: 40px;
    }

    .container {
        padding: 0 30px;
    }
}

.side {
    width: 50%;
}

@media (max-width: 1023px) {
    .side {
        width: 100%;
    }

    .container {
        flex-wrap: wrap;
    }
}

.side:nth-child(2) {
    padding-top: 115px;
    padding-left: 80px;
}

.slide {
    padding-top: 110%;
    position: relative;
    overflow: hidden;
    border-radius: 40px 40px 0 0;
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    inset: 0;
}

.layer {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    inset: 0;
    background: linear-gradient(180deg, rgba(16, 12, 8, 0) 77.75%, #100C08 100%);
}

.secondarySliderItem {
    padding-top: 20%;
    position: relative;
    overflow: hidden;
    border-radius: 0 0 40px 40px;
    border: 1px solid #FCFCFC;
    opacity: 0.4;
    transition: .3s;
}

.secondarySliderItem:hover, .activeImg {
    opacity: 1;
    cursor: pointer;
}

.secondarySliderItem img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    inset: 0;
}

@media (max-width: 767px) {
    .secondarySliderItem {
        border-radius: 0 0 20px 20px;
    }
}

.title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 40px;
}

.list {
    list-style-type: none;
    gap: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
}

.list li {
    font-weight: 300;
    position: relative;
    padding-left: 20px;
    display: flex;
    gap: 10px;
}

.circle {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    background: #A94007;
    border-radius: 50%;
}

.info {
    font-size: 22px;
    margin-bottom: 40px;
}

@media (max-width: 1365px) {
    .side:nth-child(2) {
        padding-top: 60px;
    }
}

@media (max-width: 1023px) {
    .side:nth-child(2) {
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .title {
        font-size: 28px;
    }

    .list {
        font-size: 12px;
        margin-bottom: 80px;
    }

    .info {
        font-size: 18px;
        margin-bottom: 40px;
    }
}
