.project {
    background: #ffffff2e;
    border-radius: 30px;
}

.head {
    display: flex;
    align-items: flex-start;
}

.image {
    padding: 0 15px 15px 0;
    background: #100C08;
    border-bottom-right-radius: 30px;
}

.image img {
    width: 150px;
    height: 133px;
    object-fit: cover;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 0 30px 30px 30px;
    opacity: 0.4;
    transition: .3s;
}

.image:hover img {
    opacity: 1;
}

@media (max-width: 767px) {
    .image img {
        width: 98px;
        height: 87px;
        opacity: 1;
    }
}

.description {
    padding: 30px 10px 0 35px;
    color: #CCCCCC;
}

@media (max-width: 767px) {
    .description {
        padding: 22px 10px 0 25px;
    }
}

.title {
    font-family: 'PPFragment', sans-serif;
    font-size: 24px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .title {
        font-size: 14px;
    }
}

.subDescription {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 767px) {
    .subDescription {
        font-size: 12px;
    }
}

.listItem {
    display: flex;
    align-items: center;
    gap: 5px;
}

.video {
    padding: 15px;
}

.link {
    padding-top: 55%;
    display: inline-flex;
    width: 100%;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
}

.link:hover img {
    transform: scale(1.1);
}

.link img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    inset: 0;
    transition: .3s;
    position: absolute;
}

.layer {
    width: 100%;
    height: 100%;
    inset: 0;
    position: absolute;
    background: #100C0866;
    transition: .3s;
}

.link:hover .layer {
    opacity: 0;
}

.arrow {
    width: 44px;
    height: 44px;
    background: #CCCCCC66;
    border-radius: 10px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

