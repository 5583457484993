.notText {
    margin-top: 300px;
}

@media (max-width: 767px) {
    .notText {
        margin-top: 100px;
    }
}

.texts {
    padding: 200px 230px 150px 60px;
}

.title h2 {
    display: inline-flex;
    text-align: left;
    margin-bottom: 30px;
    max-width: 350px;
}

.text {
    font-size: 20px;
    color: #D1D1D1;
}

@media (max-width: 767px) {
    .texts {
        padding: 80px 40px;
    }

    .title h2 {
        margin-bottom: 15px;
    }

    .text {
        font-size: 10px;
        line-height: 12px;
    }
}

.breadcrumbs {
    position: absolute !important;
    top: 145px;
    left: 80px;
    z-index: 2;
}

@media (max-width: 767px) {
    .breadcrumbs {
        left: 40px;
        top: 100px;
    }
}
