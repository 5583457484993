@font-face {
    font-family: 'PPFragment';
    src: url('./assets/fonts/PPFragment-SerifExtraBold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/fonts/Helvetica.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica-light';
    src: url('./assets/fonts/helvetica-light-587ebe5a59211.woff') format('woff');
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'Helvetica', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #100C08;
    color: #CCCCCC;
}

textarea::placeholder {
    font-family: 'Helvetica', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.page {
    padding-top: 127px;
}

@media (max-width: 767px) {
    padding-top: 95px;
}

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
    margin: 0;
    padding: 0;
}

a.active {
    opacity: 1 !important;
}

.swiper-button-disabled {
    opacity: 0 !important;
    pointer-events: none !important;
}

.gallerySlider .swiper-slide {
    pointer-events: none !important;
    transition: .3s !important;
}

.gallerySlider .swiper-slide-active {
    pointer-events: all !important;
}

.kfn_anim {
    visibility: visible;
    transition: opacity 1s, transform 1s;
}

.kfn_anim.active-kfn {
    visibility: visible;
}

.k-slideUp {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
}

.kfn_anim.active-kfn.k-slideUp {
    opacity: 1;
    transform: translateY(0);
    transition: 1s;
}

.k-fadeUp {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
}

.kfn_anim.active-kfn.k-fadeUp {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.k-scaleX {
    transform: scaleX(0);
}

.kfn_anim.active-kfn.k-scaleX {
    transform: scaleX(1);
    transition: 1s;
}

.k-fadeRight {
    transform: translate3d(100px, 0, 0);
    opacity: 0;
}

.kfn_anim.active-kfn.k-fadeRight {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: 1s;
}

.k-fadeLeft {
    transform: translate3d(-100px, 0, 0);
    opacity: 0;
}

.kfn_anim.active-kfn.k-fadeLeft {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: 1s;
}

.k-scale {
    transform: scale(0);
}

.kfn_anim.active-kfn.k-scale {
    transform: scale(1);
    transition: 1s;
}


.k-fadeIn {
    opacity: 0;
}

.kfn_anim.active-kfn.k-fadeIn {
    opacity: 1;
    transition: 1s;
}

.k-rectangle {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

.kfn_anim.active-kfn.k-rectangle {
    opacity: 1;
    transition: 1s;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
