.arrow {
    cursor: pointer;
    display: inline-flex;
}

.right {
    transform: rotate(180deg);
}

.arrow svg {
    transition: .3s;
}

.arrow svg:hover {
    transform: scale(1.2);
}
