.root {
    padding-top: 160px;
    padding-bottom: 200px;
}

.title {
    margin-bottom: 90px;
}

.container {
    display: flex;
    justify-content: space-between;
    gap: 0 160px;
    width: 830px;
    padding: 0 15px;
    margin: auto;
}

.side {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.chip {
    width: 100%;
    position: relative;
    border-radius: 40px;
    border: 1px solid #FFFFFF1A;
    padding: 18px 27px;
    color: #FFFFFF66;
    /*transition: .3s;*/
}

.chipTitle {
    font-size: 20px;
    transition: .3s;
}

.text {
    font-size: 14px;
    margin-top: 35px;
    position: absolute;
    opacity: 0;
}

.chip:hover .text {
    position: relative;
    opacity: 1;
    transition: .3s;
}

.chip:hover .chipTitle {
    padding-left: 50px;
}

.video {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transform: translateX(-10px);
    transition: .3s;
}

.chip:hover .video {
    transform: translateX(0);
    opacity: 1;
}

.video img {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    object-fit: cover;
}

.video svg {
    width: 15px;
    height: 15px;
    position: relative;
}

@media (max-width: 1023px) {
    .container {
        width: 100%;
        gap: 50px;
    }

    .chip .video {
        transform: translateX(0);
        opacity: 1;
    }

    .chip .text {
        position: relative;
        opacity: 1;
        transition: .3s;
    }

    .chip .chipTitle {
        padding-left: 50px;
    }
}

@media (max-width: 767px) {
    .container {
        width: 100%;
        gap: 20px;
        flex-direction: column;
    }

    .side {
        width: 100%;
    }
}
