.root {
    position: relative;
}

.rectangle {
    left: -80px;
    top: -15px;
}

.content {
    position: relative;

    font-size: 20px;
    line-height: 23px;
}

.link:last-child > .icon {
    display: none;
}

.link {
    opacity: 0.4;
}

.link:last-child {
    opacity: 1;
}

.text {
    color: #CCCCCC;
    text-decoration: none;
}

.icon {
    margin: 0 20px;
}

@media screen and (max-width: 767px) {
    .content {
        font-size: 12px;
        line-height: 14px;
    }

    .rectangle {
        left: -40px;
        top: -10px;
    }
}