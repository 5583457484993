.root {
    position: relative;
    padding: 80px 80px 125px;
    width: calc(100% - 160px);
    margin: auto auto 215px;
}

@media (max-width: 1023px) {
    .root {
        padding: 50px 50px 105px;
        width: calc(100% - 100px);
    }
}

@media (max-width: 767px) {
    .root {
        padding: 30px 0 150px 0;
        width: calc(100% - 88px);
        margin: auto auto 177px;
    }
}

.title h2 {
    text-align: right;
    margin-bottom: 80px;
}

@media (max-width: 767px) {
    .title h2 {
        padding-right: 30px;
        padding-left: 30px;
    }
}

.borderLeft {
    position: absolute;
    left: 0;
    bottom: 0;
    border: 2px solid #FCFCFC;
    border-bottom-left-radius: 50px;
    height: 50%;
    width: 80%;
    opacity: 0.2;
    border-top-color: transparent;
    border-right-color: transparent;
    pointer-events: none;
}

@media (max-width: 767px) {
    .borderLeft {
        width: 100%;
        height: 90px;
    }
}

.borderRight {
    position: absolute;
    right: 0;
    top: 0;
    border: 2px solid #FCFCFC;
    border-top-right-radius: 50px;
    height: 50%;
    width: 80%;
    opacity: 0.2;
    border-bottom-color: transparent;
    border-left-color: transparent;
    pointer-events: none;
}

@media (max-width: 767px) {
    .borderRight {
        width: 100%;
        height: 90px;
    }
}

.sliderContainer {
    display: flex;
    align-items: center;
}

.slider {
    margin: 0 40px;
}

@media (max-width: 767px) {
    .slider {
        margin: 0;
    }
}

.slideItem {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .slideItem img {
        max-width: 54px;
    }
}

.rectangle {
    right: -80px;
    top: 100%;
}

@media (max-width: 1023px) {
    .rectangle {
        right: -50px;
    }
}

@media (max-width: 767px) {
    .rectangle {
        right: -44px;
        top: calc(100% - 18px);
        z-index: -1;
    }
}

@media (max-width: 767px) {
    .sliderPrev, .sliderNext {
        display: none;
    }
}
