.root {
    width: 230px;
    height: 54px;
    background: #A94007;
    position: absolute;
}

@media (max-width: 767px) {
    .root {
        width: 128px;
        height: 34px;
    }
}
