.root {
    position: relative;
    font-size: 20px;
    color: #CCCCCC;
    opacity: 0.4;
    transition: .3s;
    cursor: pointer;
    text-transform: uppercase;
}

.root svg {
    transition: .3s;
}

.root.active svg {
    transform: rotate(180deg);
}

.root:hover {
    opacity: 1;
}

.activeLang {
    display: flex;
    gap: 16px;
    align-items: center;
}

.langs {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    opacity: 0;
    transition: .3s;
    pointer-events: none;
    width: 100%;
}

.active .langs {
    opacity: 1;
    pointer-events: all;
}

.lang {
    padding: 5px 0;
    transition: .3s;
}

.lang:hover {
    color: #A94007;
}
