.paginationList {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-bottom: 120px;
    gap: 10px;
}

@media (max-width: 767px) {
    .paginationList {
        gap: 5px;
    }

    .paginationList li {
        font-size: 12px;
    }
}

.paginationList li {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    line-height: 30px;
    font-weight: 700;
    cursor: pointer;
    transition: .3s;
    border: 1px solid transparent;
}

.paginationList li a {
    width: 100%;
    height: 100%;
    text-align: center;
}

.paginationList li.selected, .paginationList li:hover {
    border: 1px solid #FCFCFC;
}
