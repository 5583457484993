.formContent {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 60px;
}

.common {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: min(700px, 100%);
}

.page .common {
    gap: 30px
}

.radioTitle {
    margin-bottom: 40px;
    color: #FFFFFF66;
    font-size: 20px;
    line-height: 23px;
    font-weight: normal;
}

.radioContainer {
    display: flex;
    gap: 40px;
}

.inputContainer {
    display: flex;
    gap: 58px;
}

.inputContainer > * {
    flex: 1;
}

.textarea {
    height: 120px;
}

@media screen and (max-width: 767px) {
    .radioTitle {
        margin-bottom: 15px;
        font-size: 12px;
        line-height: 14px;
    }

    .formContent {
        gap: 15px;
        margin-bottom: 40px;
    }

    .common {
        width: 100%;
    }

    .inputContainer {
        flex-direction: column;
        gap: 15px;
    }

    .textarea {
        height: 80px;
    }
}