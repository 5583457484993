.head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
}

.title h2 {
    text-align: left;
}

@media (max-width: 1170px) {
    .title h2 {
        font-size: 44px;
        line-height: 44px;
    }
}

.side {
    display: flex;
    align-items: center;
    gap: 44px;
}

@media (max-width: 1170px) {
    .side {
        gap: 20px;
    }
}

.arrows {
    display: flex;
    align-items: center;
    gap: 25px;
}

.sliderContainer {
    padding-top: 50%;
    position: relative;
}

.slider {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    overflow: visible !important;
}

.slide {
    width: 100%;
    height: 100%;
    background: #100C08;
}

.item {
    position: absolute;
    overflow: hidden;
    background: #100C08;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
}

.item:nth-child(1) {
    width: 37%;
    height: 57%;
    left: 0;
    top: 0;
    border-radius: 20px 0 0 0;
}

.item:nth-child(2) {
    width: 31%;
    height: calc(57% + 140px);
    left: calc(37% + 10px);
    bottom: 43%;
}

.item:nth-child(3) {
    width: calc(32% - 20px);
    border-radius: 0 20px 0 0;
    height: 43%;
    top: 0;
    right: 0;
}

.item:nth-child(4) {
    width: 43%;
    height: calc(43% - 10px);
    left: 0;
    bottom: 0;
    border-radius: 0 0 0 20px;
}

.item:nth-child(5) {
    width: 25%;
    height: calc(43% - 10px);
    left: calc(43% + 10px);
    bottom: 0;
}

.item:nth-child(6) {
    width: calc(32% - 20px);
    height: calc(57% - 10px);
    right: 0;
    bottom: 0;
    border-radius: 0 0 20px 0;
}

.layer {
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(16, 12, 8, 0) 69.24%, #100C08 100%);
    opacity: 0;
    transition: .3s;
}

.item:hover .layer {
    opacity: 1;
}

.text {
    position: absolute;
    left: 45px;
    bottom: 45px;
    color: white;
    opacity: 0;
    transition: .3s;
}

.text h2 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.text h3 {
    font-weight: 300;
}

.item:hover .text {
    opacity: 1;
}
