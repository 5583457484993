.root {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(16, 12, 8, 0.78);
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    padding: 20px;
    position: relative;
    border-radius: 40px;
    border: 1px solid #A94007;
    width: 80%;
}

.container video {
    width: 100%;
    height: auto;
}

.close {
    width: 47px;
    height: 47px;
    background: #FFFFFF0D;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    position: absolute;
    right: 40px;
    top: 40px;
}

@media (max-width: 767px) {
    .container {
        padding: 107px 15px 15px;
        width: calc(100% - 40px);
    }

    .close {
        right: 15px;
        top: 20px;
    }
}
