.root {
    width: 100%;
    height: calc(100vh - 120px);
    position: relative;
}

.mainSlider {
    position: absolute;
    width: 100%;
    height: 100%;
}

.mainSlider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.layer {
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, #100C08 0%, rgba(16, 12, 8, 0) 66.5%, #100C08 100%);
}

.footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px 0 110px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    transform: translateY(-50%);
}

@media (max-width: 1023px) {
    .footer {
        padding: 0 40px;
    }

    .arrow {
        display: none !important;
    }
}

.secondarySliderContent {
    width: calc(100% - 420px);
    display: flex;
    padding-right: 50px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

@media (max-width: 1023px) {
    .secondarySliderContent {
        width: 100%;
        padding-right: 0;
    }
}

.secondarySliderItem {
    cursor: pointer;
    background: #100C08;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid #FCFCFC;
}

.secondarySliderItem div {
    padding-top: 72%;
    position: relative;
}

.secondarySliderItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
    transition: .3s;
    opacity: 0.4;
    backface-visibility: hidden;
}

.secondarySliderItem:hover img {
    opacity: 1;
}

.secondarySliderItem.swiper-slide-active img {
    opacity: 1;
}

.secondarySlider .swiper-slide-active img {
    opacity: 1;
}

.activeImg {
    opacity: 1 !important;
}

.description {
    width: 410px;
    padding: 40px 90px;
    background: #CCCCCC1A;
    border-radius: 30px;
}

@media (max-width: 1023px) {
    .description {
        position: absolute;
        right: 40px;
        bottom: calc(100% + 10px);
    }
}

@media (max-width: 767px) {
    .description {
        width: auto;
        padding: 34px 50px;
    }
}

.title {
    font-size: 24px;
    line-height: 24px;
    font-family: 'PPFragment', sans-serif;
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .title {
        font-size: 12px;
        line-height: 12px;
    }
}

.subDescription {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    color: #CCCCCC;
}

@media (max-width: 767px) {
    .subDescription {
        font-size: 10px;
    }
}
