.root {
    padding: 0 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.item {
    width: 25%;
    height: 300px;
    transition: .3s;
}

.item.active {
    width: 35%;
}

.item img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
