.root {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #A94007CC;
    z-index: 4;
    animation: fadeIn .3s both;
    padding: 150px 80px;
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.button {
    position: absolute;
    right: 95px;
    top: 40px;
}

@media (max-width: 767px) {
    .button {
        top: 25px;
        right: 40px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.container a {
    color: #CCCCCC;
    text-decoration: none;
}

.lang {
    opacity: 1;
    font-size: 16px;
}
