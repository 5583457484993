.button {
    padding: 16px 32px;
    font-size: 20px;
    line-height: 23px;
    border-radius: 20px;
    border: 1px solid;
    transition: all .2s;

    cursor: pointer;
}

.button.primary {
    color: #ccc;
    background: #FFFFFF0D;
    border-color: #FFFFFF0D;
}

.button.primary:hover,
.button.primary:active {
    color: #100C08;
    background: #FFFFFF;
    border-color: #FFFFFF;
}

.button.secondary {
    color: #FFFFFF66;
    border-color: #FFFFFF1A;
    background: transparent;
}

.button.secondary:hover {
    color: #FFFFFF;
}

.button.secondary:active {
    color: #FFFFFF;
    border-color: #FFFFFF;
}

@media screen and (max-width: 767px) {
    .button {
        font-size: 12px;
        line-height: 14px;
        padding: 12px 32px;
    }
}