.textarea {
    padding: 18px 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: transparent;
    border-radius: 20px;
    color: #fff;
    resize: none;

    font-size: 20px;
    line-height: 23px;
    box-sizing: border-box;
}

.textarea::placeholder {
    color: rgba(255, 255, 255, 0.4);
}

@media screen and (max-width: 767px) {
    .textarea {
        padding: 12px 16px;
        font-size: 12px;
        line-height: 14px;
    }
}