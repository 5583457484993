.container {
    padding-left: 80px;
    padding-right: 80px;
}

@media (max-width: 767px) {
    .container {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.title h2 {
    text-align: left;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .title h2 {
        margin-bottom: 100px;
    }
}

.projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 70px 160px;
    justify-content: space-between;
    margin-bottom: 170px;
}

@media (max-width: 1200px) {
    .projects {
        grid-gap: 70px;
    }
}

@media (max-width: 1023px) {
    .projects {
        grid-template-columns: repeat(1, 1fr);
    }
}

.projectItemContent:nth-child(odd) {
    transform: translateY(40px);
}

@media (max-width: 1023px) {
    .projectItemContent:nth-child(odd) {
        transform: none;
    }
}
