.title h2 {
    text-align: left;
    margin-bottom: 90px;
}

.container {
    padding-left: 70px;
    padding-right: 70px;
}

.products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: 30px;
    margin-bottom: 60px;
}

.product {
    position: relative;
    text-decoration: none;
    display: block;
}

.product:nth-child(1),
.product:nth-child(3),
.product:nth-child(4),
.product:nth-child(5),
.product:nth-child(8),
.product:nth-child(9) {
    width: 26%;
}

.product:nth-child(2),
.product:nth-child(6),
.product:nth-child(7) {
    width: calc(48% - 60px);
}

.image {
    height: 330px;
    position: relative;
    overflow: hidden;
    background: #100C08;
    margin-bottom: 15px;
}

.image img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
    transition: .3s;
}

.product:hover .image img {
    opacity: 1;
}

.productTitle {
    text-decoration: none;
    color: #D1D1D1;
    font-weight: 300;
}

@media (max-width: 1023px) {
    .products {
        gap: 15px;
    }

    .product:nth-child(1),
    .product:nth-child(4),
    .product:nth-child(5),
    .product:nth-child(8),
    .product:nth-child(9) {
        width: 37%;
    }

    .product:nth-child(2),
    .product:nth-child(3),
    .product:nth-child(6),
    .product:nth-child(7) {
        width: calc(63% - 15px);
    }
}

@media (max-width: 767px) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .image {
        height: 145px;
        margin-bottom: 10px;
    }

    .productTitle {
        font-size: 12px;
    }
}

.pagination {
    display: flex;
    justify-content: center;
}
