.imageWrapper {
    margin-bottom: 40px;
    position: relative;
}

.imageOverlay {
    position: absolute;
    inset: 0;
    border-radius: 0 20px 0 0;
    background: linear-gradient(270deg, rgba(169, 64, 7, 0) 83.68%, #A94007 100%) rgba(0,0,0, .2) ;
    z-index: 1;
    opacity: 1;

    transition: opacity .5s;
}

.imageOverlay:hover {
    opacity: 0;
}

.root:nth-child(even) {
    text-align: right;
}

.root:nth-child(even) .imageOverlay {
    background: linear-gradient(90deg, rgba(169, 64, 7, 0) 83.68%, #A94007 100%) rgba(0,0,0, .2);
}

.image {
    width: 100%;
    aspect-ratio: 2/1;
    position: relative;
    border-radius: 0 20px 0 0;

}

.title {
    font-size: 20px;
    line-height: 23px;
    color: #CCCCCC;
}

@media screen and (max-width: 767px) {
    .imageWrapper {
        margin-bottom: 15px;
    }
    .title {
        font-size: 12px;
        line-height: 14px;
    }
}