.root {
    padding: 0 80px;
    position: relative;
    margin-bottom: 120px;
}

.header {
    display: flex;
    gap: 80px;
    margin-bottom: 40px;
}

.title h2 {
    text-align: left;
}

.decorationWrapper {
    flex: 1;
    position: relative;
}

.decoration {
    pointer-events: none;
    position: absolute;
    width: 100%;
    top: 50%;
}

.borderRight {
    height: 230px;
    border-top-right-radius: 50px;
    border: 2px solid #FCFCFC;
    width: 100%;
    opacity: 0.2;
    border-bottom-color: transparent;
    border-left-color: transparent;
    margin-bottom: 40px;
    pointer-events: none;
}

.icons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    margin-right: -12.5px;
    margin-bottom: 40px;
    pointer-events: all;
}

.icons > a {
    width: 25px;
    height: 25px;
}

.rectangle {
    display: block;
    right: -80px;
}

@media screen and (max-width: 1040px) {
    .icons,
    .rectangle {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .root {
        padding: 0 30px;
    }

    .header {
        gap: 22px;
        margin-bottom: 50px;
    }

    .borderRight {
        top: 0;
        height: 105px;
    }
}
